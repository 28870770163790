import React from "react";

import {redirectIfAuth} from "@/util/authUtils";

import Login from "@/components/Login/Login";

const Main = () => {
  return <Login />;
};

Main.getInitialProps = async (ctx) => {
  await redirectIfAuth(ctx);
  return {};
};

export default Main;
