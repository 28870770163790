import React, {useState} from "react";
import {useForm} from "react-hook-form";
import Link from "next/link";
import cn from "classnames";
import {useRouter} from "next/router";
import authApi from "@/api/auth/auth";
import {Icon} from "UI";
import Logo from "@/assets/drive-sales.svg";

import styles from "./Login.module.css";

const Login = () => {
  const {handleSubmit, register, errors} = useForm();
  const router = useRouter();
  const [backendError, setBackendError] = useState("");

  const onSubmit = async (data) => {
    try {
      const res = await authApi().login(data.email, data.password);
      console.log(`res.headers, `, res);
      router.replace("/");
    } catch (error) {
      setBackendError(error.response.data.reason);
    }
  };

  return (
    <div className={cn("container-fluid mx-auto ", styles.container)}>
      <div className="row" style={{marginTop: "25vh"}}>
        <div
          className={cn(
            "col",
            "d-flex",
            "justify-content-center",
            "align-items-center",
            styles["logo-placeholder"]
          )}
        >
          <img src={Logo} width={300} />
        </div>
      </div>
      <div className="row" style={{marginTop: "5vh"}}>
        <div className="col d-flex  justify-content-center align-items-center">
          <p className={styles["login-text"]}>Log in</p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col d-flex justify-content-center align-items-center">
          <div className="form-group">
            <label className={styles["label-email"]}> Email</label>
            <br></br>
            <input
              type="email"
              className={styles["input-field"]}
              placeholder={"Enter email"}
              name="email"
              ref={register({required: true, minLength: 3})}
            />
            {errors.username && <span className={styles.error}>This field is required.</span>}
            {backendError && <span className={styles.error}>{backendError}</span>}
            <div className="form-group" style={{marginTop: 12}}>
              <label className={styles["label-password"]}>Password</label>
              <br></br>
              <input
                type="password"
                className={styles["input-field"]}
                placeholder={"Password"}
                name="password"
                ref={register({required: true, minLength: 3})}
              />
              {errors.username && <span className={styles.error}>This field is required.</span>}
              {backendError && <span className={styles.error}>{backendError}</span>}
            </div>
          </div>
        </div>
        <div className={styles["signup-row"]}>
          <div className="col d-flex  justify-content-center align-items-center">
            <div className="row">
              <div className="col d-flex  align-items-start">
                <Icon icon="information-icon" className={styles["icon-info"]} />
                <p className={styles["road-to-signup"]}>
                  If you don't have an account&nbsp;
                  <Link href="/register">
                    <span className={styles["login-details-sign-up"]}>Sign up</span>
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex  justify-content-center align-items-center">
            <div className="row">
              <div className="col d-flex  align-items-start">
                <Link href="/email-resend">
                  <span className={styles["login-forgot-password"]}>
                    {" "}
                    Resend verification mail &#63;
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex  align-items-center justify-content-center">
            {/* <p className={styles["road-to-signup"]}> */}
            <Link href="/forgot-password">
              <span className={styles["login-forgot-password"]}>Forgot password ?</span>
            </Link>

            {/* </p> */}
          </div>
        </div>
        <div className="row">
          <div className="col d-flex  justify-content-center align-items-center">
            <input type="submit" value={"Log in"} className={styles["btn-login"]} ref={register} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
